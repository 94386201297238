import React from "react";
import { ConfigProvider, Modal } from "antd";
import CustomIcon from "../../Assets/CustomIcon";

const LoadSaveViewModal = ({
  isLoadSaveViewModalOpen,
  handleLoadSaveViewModelClose,
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleColor: "#111A21",
            titleFontSize: 20,
          },
        },
      }}
    >
      <Modal
        title="Your Saved Views"
        open={isLoadSaveViewModalOpen}
        onCancel={handleLoadSaveViewModelClose}
        footer={null}
      >
        <div className="flex flex-col gap-8 pt-8 pb-3">
          <input
            type="text"
            className="text-sm font-normal leading-tight border border-zinc-200 py-2 px-3 rounded-md"
            placeholder="search your view"
          />

          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between ">
              <p>Trading View</p>
              <CustomIcon iconName={"deleteIcon"} className="cursor-pointer" />
            </div>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default LoadSaveViewModal;
