/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { widget } from "../charting_library";
import configureSymbol from "../chartUtils";
import { getUserId } from "../components/TVChartContainer";
import { connect } from "react-redux";

function getUrl() {
  const env_var =
    typeof window._env_ !== "undefined" ? window._env_ : process.env;
  return env_var.REACT_APP_CHART_URL;
}

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const MultiChartContainer = ({
  symbol = "NEPSE",
  interval = "D",
  userId = getUserId(),
  containerId = "MultiChartContainer",
  chartsStorageUrl = getUrl() + "/chart/saveChart",
  datafeedUrl = getUrl() + "/tv",
  libraryPath = "/charting_library/",
  chartsStorageApiVersion = "1.1",
  clientId = "systemx.tradingview.com",
  fullscreen = false,
  autosize = true,
  studiesOverrides = {},
  timezone = "exchange",
  width,
  height,
  theme,
  onWidgetReady,
  ...props
}) => {
  const prevPropsRef = useRef({ symbol });
  const buttonClickedRef = useRef(false);
  const tvWidgetRef = useRef(null);

  const createWidget = (themeToUse) => {
    if (tvWidgetRef.current) {
      tvWidgetRef.current.remove();
    }

    const widgetOptions = {
      symbol: symbol,
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(datafeedUrl),
      symbol_search_request_delay: 1000,
      interval: interval,
      container: containerId,
      library_path: libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_saveload",
      ],
      enabled_features: [
        "study_templates",
        "chart_templates",
        "save_chart_properties_to_local_storage",
        "save_chart_properties_on_server",
        "chart_save_remote_load_enable"
      ],
      charts_storage_url: chartsStorageUrl,
      charts_storage_api_version: chartsStorageApiVersion,
      client_id: clientId,
      user_id: userId,
      fullscreen: fullscreen,
      autosize: autosize,
      studies_overrides: studiesOverrides,
      timezone: timezone,
      width: width,
      height: height,
      theme: themeToUse,
      toolbar_bg: themeToUse === 'Dark' ? "" : "#f4f7f7",
      charts_storage_save_load_key: `tradingview_${containerId}`,
      save_load_adapter: {},
    };

    const tvWidget = new widget(widgetOptions);
    tvWidgetRef.current = tvWidget;


    tvWidget.onChartReady(() => {
      if (onWidgetReady) {
        onWidgetReady(tvWidget);
      }
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Toggle Adjusted/Unadjusted");
        button.classList.add("apply-common-tooltip");

        button.addEventListener("click", () => {
          buttonClickedRef.current = true;
          let currentSymbol = tvWidget
            .activeChart()
            .symbol()
            .toLocaleLowerCase();
          currentSymbol = configureSymbol(currentSymbol);

          if (currentSymbol.includes("_adj")) {
            const stockSymbol = currentSymbol.split("_")[0];
            tvWidget.activeChart().setSymbol(stockSymbol);
            button.innerHTML = "Adjusted";
          } else {
            tvWidget.activeChart().setSymbol(currentSymbol + "_adj");
            button.innerHTML = "Unadjusted";
          }
        });
        button.innerHTML = "Unadjusted";
      });

      tvWidget
        .activeChart()
        .onSymbolChanged()
        .subscribe(null, () => {
          let currentSymbol = tvWidget
            .activeChart()
            .symbol()
            .toLocaleLowerCase();
          currentSymbol = configureSymbol(currentSymbol);

          if (
            !buttonClickedRef.current &&
            prevPropsRef.current.symbol !== currentSymbol &&
            !currentSymbol.includes("_index")
          ) {
            prevPropsRef.current.symbol = currentSymbol + "_adj";
            tvWidget.activeChart().setSymbol(currentSymbol + "_adj");
          }
          buttonClickedRef.current = false;
        });
    });
  };

  useEffect(() => {
    createWidget(theme);

    return () => {
      if (tvWidgetRef.current !== null) {
        tvWidgetRef.current.remove();
      }
    };
  }, [theme]);

  return <div id={containerId} className={"MultiChartContainer"} />;
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(MultiChartContainer);
