import React from "react";
import { ConfigProvider, Modal } from "antd";

const SaveViewModal = ({
  isSaveModalOpen,
  handleSaveModelClose,
  hanldleSaveOk,
  setViewName,
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleColor: "#111A21",
            titleFontSize: 20,
          },
        },
      }}
    >
      <Modal
        title="Save View"
        open={isSaveModalOpen}
        onCancel={handleSaveModelClose}
        footer={(_) => (
          <>
            <div className="flex flex-row w-full justify-center">
              <button
                className="w-full py-2 px-3 rounded-[32px] shadow bg-blue-600 text-neutral-50 text-sm font-medium leading-tight"
                onClick={hanldleSaveOk}
              >
                Save
              </button>
            </div>
          </>
        )}
      >
        <div className="flex flex-col gap-2 justify-start py-6">
          <p className="text-gray-900 text-sm font-semibold leading-[14px]">
            Save view
          </p>
          <input
            type="text"
            className="text-sm font-normal leading-tight px-4 py-3 bg-gray-100 rounded-md"
            placeholder="Enter the name for this view"
            onChange={(e) => setViewName(e.target.value)}
          />
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default SaveViewModal;
