import axios from "axios";

export function getEnvironmentVariables() {
  return typeof window._env_ !== "undefined" ? window._env_ : process.env;
}

export function getToken() {
  const searchParams = new URLSearchParams(window.location.search);
  return (
    searchParams.get("tk") || searchParams.get("t") || "NO TOKEN AVAILABLE"
  );
}

function createAxiosInstances() {
  const env = getEnvironmentVariables();
  const tokenValue = getToken();
  let bytes = window.atob(tokenValue);
  let originalText = JSON.parse(bytes);
  const defaultToken = `Bearer ${originalText.token}`;

  const defaultHeaders = {
    Authorization: defaultToken,
  };

  const chartInstance = axios.create({
    baseURL: env.REACT_APP_CHART_URL,
    headers: defaultHeaders,
  });

  const baseInstance = axios.create({
    baseURL: env.REACT_APP_BASE_URL,
    headers: defaultHeaders,
  });

  const loginServerInstance = axios.create({
    baseURL: env.LOGIN_SERVER_URL,
    headers: defaultHeaders,
  });

  return {
    chart: chartInstance,
    base: baseInstance,
    loginServer: loginServerInstance,
    createCustom: (customBaseURL, options = {}) => {
      const {
        authHeader = defaultToken,
        additionalHeaders = {}
      } = options;

      return axios.create({
        baseURL: customBaseURL,
        headers: {
          ...defaultHeaders,
          ...additionalHeaders,
          Authorization: authHeader
        },
      });
    },
  };
}

export const {
  chart: axiosOrder,
  base: axiosBase,
  loginServer: axiosLoginServer,
  createCustom: createCustomAxios,
} = createAxiosInstances();